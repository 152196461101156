@import "../../../styles/var";

.block {
}
.blockBody {
}
.blockRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.blockRowLeft {
    flex: 0 1 634px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.blockRowLeftText {
    font-weight: 500;
    margin-bottom: 60px;
    @media (max-width: $md3+px) {
        max-width: 500px;
    }
}
.blockRowLeftForm {
}
.blockRowLeftFormInput {
    margin-bottom: 18px;
    border-radius: 40px;
    border: 1px solid #bfaa8a;
    max-width: 457px;
    height: 54px;
    line-height: 54px;
    @media (max-width: $md6+px) {
        height: 45px;
        line-height: 45px;
    }
    &.error {
        border: 1px solid red;
    }
    input {
        width: 100%;
        background-color: transparent;
        padding: 0 30px;
        @media (max-width: $md6+px) {
            padding: 0 24px;
        }
    }
}
.blockRowLeftFormCheckout {
    margin-top: 12px;
    display: flex;
    cursor: pointer;
    line-height: 1 !important;
    span {
        border-radius: 2px;
        border: 1px solid #bfaa8a;
        width: 12px;
        display: block;
        height: 12px;
        margin-right: 8px;
    }
    &.error {
        span {
            border: 1px solid red;
        }
    }
    &.active {
        span {
            background-color: #bfaa8a;
        }
    }
}
.blockRowLeftFormBtn {
    margin-top: 40px;
}
.blockRowRight {
    flex: 0 1 571px;
    @media (max-width: $md3+px) {
        margin-top: 60px;
        flex: 0 1 100%;
    }
    img {
        width: 100%;
    }
}

.blockRowRight {
}
.blockRowRightContent {
    border-radius: 20px;
    background: #f4f4f4;
    padding: 40px 60px 60px 60px;
    @media (max-width: $md5+px) {
        padding: 30px 30px 30px 30px;
    }
    @media (max-width: $md6+px) {
        padding: 20px;
    }
}
.blockRowRightContentTitle {
    margin-bottom: 40px;
}
.blockRowRightContentSubTitle {
    margin-bottom: 40px;
}
.blockRowRightContentLocation {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    img, svg {
        width: 24px;
        height: 24px;
        margin-right: 20px;
    }
}
.blockRowRightContentBottom {
    display: flex;
    gap: 40px;
}
.blockRowRightContentBottomItem {
    p {
        &:first-child {
            margin-bottom: 6px;
        }
        &:nth-child(2) {
            font-weight: 500 !important;
        }
    }
}

.mail {
    margin-bottom: 15px!important;
}