@import "../../../styles/var";

.block {
}
.blockBody {
    padding: 80px 0;
    background: url("../../../assets/img/main/6/1.png") center 0 / cover no-repeat;
    @media (max-width: $md3+px) {
        padding: 60px 0;
    }
}
.blockTitle {
    max-width: 571px;
    margin: 0 auto 60px auto;
    text-align: center;
    @media (max-width: $md2+px) {
        max-width: 500px;
        margin: 0 auto 40px auto;
    }
}
.blockRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.blockRowColumn {
    flex: 0 1 392px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 20px 1px rgba(103, 88, 65, 0.1);
    padding: 40px 40px 40px 60px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        margin-bottom: 40px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 370px;
        padding: 30px 20px 30px 50px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 48%;
    }
    @media (max-width: $md3+px) {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-bottom: 30px;
        }
        padding: 40px 40px 40px 60px;
    }
}
.blockRowColumnTitle {
    font-weight: 500 !important;
    margin-bottom: 12px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: -28px;
        top: 10px;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #bfaa8a;
        @media (max-width: $md2+px) {
            top: 7px;
            left: -20px;
        }
        @media (max-width: $md3+px) {
            left: -28px;
            top: 7px;
        }
    }
}
.blockRowColumnText {
    font-weight: 300 !important;
}

.mobSlider {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        max-width: 500px;
        margin: 0 auto;
    }
}

.blockRowLeftBottom {
    display: flex;
    justify-content: space-between;
    width: 70px;
    margin: 0 auto;
}
.blockRowLeftBottomLeft {
}
.blockRowLeftBottomRight {
    transform: rotate(180deg);
}
