@import "../../../styles/var";

.approach {
    background-color: #f4f4f4;
    padding: 81px 0 62px 0;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        right: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        width: 683px;
        height: 525px;
        background: url("../../../assets/img/main/4/1.png") right bottom / contain no-repeat;
        @media (max-width: $md2+px) {
            width: calc(683px * 0.8);
            height: calc(525px * 0.8);
        }
        @media (max-width: $md3+px) {
            display: none;
        }
    }
    @media (max-width: $md3+px) {
        padding: 60px 0 0px 0;
    }
}
.approachBody {
}
.approachContent {
    max-width: 666px;
    @media (max-width: $md2+px) {
        max-width: 566px;
    }
}
.approachContentTitle {
    margin-bottom: 40px;
}
.approachContentText {
}
.approachContentList {
    margin: 40px 0;
    li {
        padding-left: 55px;
        margin-bottom: 25px;
        position: relative;
        &:after {
            content: "";
            display: inline-block;
            left: 0;
            top: 0;
            position: absolute;
            width: 33.55px;
            height: 28.73px;
            background: url("../../../assets/img/main/4/2.png") 0 0 / contain no-repeat;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.approachContentListItem {
}

.approachMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        margin-top: 60px;
        text-align: right;
        img {
            max-width: 100%;
        }
    }
}
