@import "../../../styles/var";

.why {
}
.whyBody {
}
.whyTitle {
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: $md5+px) {
        margin-bottom: 40px;
    }
}
.whyRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.whyRowColumn {
    flex: 0 1 666px;
    padding: 40px 40px 75px 40px;
    @media (max-width: $md2+px) {
        flex: 0 1 588px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 20px !important;
        padding: 40px 20px 25px 20px;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    &:first-child,
    &:nth-child(2) {
        margin-bottom: 20px;
    }
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 20px 1px rgba(103, 88, 65, 0.1);
}
.whyRowColumnImg {
    margin-bottom: 20px;
}
.whyRowColumnTitle {
    margin-bottom: 20px;
}
.whyRowColumnText {
}
