@import "../../../styles/var";

.banner {
    padding: 193px 0 147px 0;
    position: relative;
    background-color: #f4f4f4;
    @media (max-width: $md2+px) {
        padding: 150px 0 120px 0;
    }
    @media (max-width: $md3+px) {
        padding: 120px 0 0 0;
    }
}
.bannerBody {
}
.bannerImg {
    position: absolute;
    pointer-events: none;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-position: right top;
        object-fit: contain;
    }
}
.bannerOverTitle {
    margin-bottom: 10px;
}
.bannerTitle {
    max-width: 600px;
    margin-bottom: 26px;
    @media (max-width: $md6+px) {
        max-width: 400px;
    }
}
.bannerText {
    max-width: 676px;
    margin-bottom: 66px;
    @media (max-width: $md2+px) {
        max-width: 500px;
    }
    @media (max-width: $md6+px) {
        max-width: 320px;
    }
}
.bannerBtn {
}

.bannerMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        margin-top: 80px;
        img {
            width: 100%;
        }
    }
}
