@import "../../../styles/var";

.brokers {
}
.brokersBody {
}
.brokersRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.brokersRowLeft {
    flex: 0 1 520px;
    @media (max-width: $md2+px) {
        flex: 0 1 480px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.brokersTitle {
    margin-bottom: 40px;
    @media (max-width: $md3+px) {
        max-width: 505px;
        text-align: center;
        margin: 0 auto 20px auto;
    }
}
.brokersRowLeftText {
    max-width: 450px;
    @media (max-width: $md3+px) {
        max-width: 400px;
        text-align: center;
        margin: 0 auto 60px auto;
    }
}
.brokersRowRight {
    flex: 0 1 611px;
}
.brokersRowRightWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 65px 50px;
    justify-content: center;
    align-items: center;
    @media (max-width: $md2+px) {
        gap: 35px 40px;
    }
    @media (max-width: $md5+px) {
        gap: 20px 0;
        text-align: center;
    }
}
.brokersRowRightItem {
    @media (max-width: $md5+px) {
        flex: 0 1 50%;
        width: 50%;
    }
    @media (max-width: 500px) {
        img {
            height: 25px;
            max-width: 100%;
        }
    }
}
