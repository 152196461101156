@import "../../../styles/var";

.button {
    padding: 14px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;

    border-radius: 30px;
    font-family: $inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #ffffff;
    transition: all 0.3s;

    border-radius: 40px;
    background: linear-gradient(100deg, #bfaa8a 16.76%, #675841 87.54%);
    box-shadow: 0px 4px 4px 0px rgba(103, 88, 65, 0.2);
    border-radius: 40px;
    @media (max-width: $md2+px) {
        font-size: 16px;
        line-height: 19px;
    }

    &:hover {
        background: linear-gradient(100deg, #675841 16.76%, #bfaa8a 87.54%);
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
